<template>
  <transition name="modal" onload="initialize()">
    <div class="modal-mask" :id="id" v-if="showModal">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <div id="map_canvas" style="width:100%; height:100%"></div>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
              <button class="modal-default-button" @click="showModal = false">
                Fechar
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAdwxi7P7SUDsbcxa3RRcQ315FDIOh1log"></script>

<script>
export default{
  data() {
    return {
      showModal: false
    }
  },
  props: {
    id: '',
    lat: '',
    lng: ''
  },

  created() {
    this.$root.$on('open-modal', id => {
      if(this.id === id){
        this.showModal = true;
         var mapOptions = {
          center: new google.maps.LatLng(this.lat, this.lng),
          zoom: 8,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map(document.getElementById("map_canvas"),
            mapOptions);
      }

    })
  }
}
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80vw;
  height: 80vh;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
