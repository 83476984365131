<template src="./laboratorySearch.html" />

<script>
import { useStore } from "@/store/store.js"
import { computed, defineComponent } from "vue"
import Modal from "@/components/modal-maps/modal.component.vue"
import mixins from "@/mixins/mixins.js"

export default defineComponent({
  name: "LaboratorySearch",

  mixins: [mixins],
  components: {
    Modal,
  },

  data: () => ({
    cep: null,
    cepInvalid: false,
    cepPharmacy: "",
    address: "",
    lat: "",
    lng: "",
    errorStr: "",
    labs: [],
    labPaginated: [],
    labPages: 0,
    labCurrent: 1,    
    mapLaboratory: false,
    mapCenter: {},
  }),

  computed: {
    isSearchDisabled () {
      return !this.cep || this.cep.length < 9
    },
    isPaginated () {
      return this.labPages > 1
    },
    formattedAddress () {
      if (!this.address) {
        return null
      } else {
        if (typeof this.address === "string") {
          return this.address
        } else {
          const { street: s, neighborhood: n, city: c, state: t } = this.address
          return `${s}, ${n} - ${c} - ${t}, ${this.cep}`
        }
      }
    },
  },
  
  setup () {
    const store = useStore()

    return {
      isMobile: computed(() => store.getters.isMobile),
      getGoogleAddress: (data) => store.dispatch("getGoogleAddress", data),
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },

  mounted () {
    this.userId = this.$session.get("id")
  },
  
  methods: {
    async searchAddress() {
      this.cepInvalid = false
      this.labs = []

      if (!this.isSearchDisabled) {
        const response = await this.getAddressByZipcode(this.cep)

        if (response && response.address) {
          this.lat = response.lat
          this.lng = response.lng
          
          await this.setDataLocation(response)

          this.getLabs()
        } else {
          this.address = null
          this.cepInvalid = true
  
          this.showNotifyWarning(
            `Não foi possível localizar o endereço através do CEP digitado.
            Digite um CEP válido e tente novamente.`,
            6000
          )
        }
      }
    },

    async getLabs() {
      this.axios.post(
        `${this.$store.state.filooServer}dasa/labs-distance`,
        { lat: this.lat, lng: this.lng },
        { headers: { Authorization: this.$session.get("bearer") }}
      )
        .then(async ({ data }) => {
          this.labs = []
          
          await data.forEach((lab) => {
            if (!this.labs.find(({ address }) => (
              JSON.stringify(address) === JSON.stringify(lab.address)
            ))) {
              lab.formattedDistance = Math.floor(lab.distance) > 1000
                ? `${Math.floor(lab.distance/100)/10}km`
                : `${Math.floor(lab.distance)}m`

              this.labs.push(lab)
            }
          })

          this.labPaginated = this.labs.slice(0, 9);
          this.labPages = Math.ceil(this.labs.length / 9)
        })
        .catch((error) => {
          if (this.cep) {
            this.showNotifyWarning(
              `Não foi possível localizar um laboratório através do CEP
              digitado. Por favor, digite outro CEP e tente novamente.`,
              6000
            )
          }
          console.error(error)
        })
        .finally(() => this.$vs.loading.close())
    },

    getGeolocation () {
      this.labs = []

      if (!("geolocation" in navigator)) {
        this.error = "Geolocalização não esta habilitada."
      } else {
        this.$vs.loading()

        navigator.geolocation.getCurrentPosition(
          async ({ coords }) => {
            if (coords.latitude && coords.longitude) {
              const response = await this.getGoogleAddress({
                lat: coords.latitude,
                lng: coords.longitude,
              })

              if (response) {
                this.lat = coords.latitude
                this.lng = coords.longitude
                
                await this.setDataLocation(response, true)

                this.getLabs()
              } else {
                this.showNotifyError("CEP inválido", 6000)
              }
            }
          },
          (err) => {
            this.error = err.message
            this.showNotifyWarning(
              `Geolocalização desabilitada. Para utilizar essa funcionalidade
              habilite essa funcionalidade do seu navegador e tente novamente.`,
              9500
            )
          }
        )
      }
    },

    setDataLocation (data, isGeolocation = false) {
      const address = isGeolocation ? data.address_components : data.address

      if (isGeolocation) {
        this.cep = address.find((adr) => adr.types.includes("postal_code")).short_name
        this.address = data.formatted_address
      } else {
        this.cep = address.zipcode
        this.address = address
      }
    },

    async showModalMap(lab) {
      this.mapLaboratory = await lab
      this.mapCenter = await {
        lat: Number(lab.address.latitude),
        lng: Number(lab.address.longitude),
      }
      this.$bvModal.show("modal-lab-location")
    },
    changePagination() {      
      const initialPage = Math.max(this.labCurrent - 1, 0)
      const endPage = initialPage + 1

      this.labPaginated = this.labs.slice(initialPage * 9, endPage * 9)

      window.scrollTo({ top: 0, behavior: "smooth" })
    },
  },
  watch: {
    cep (newVal) {
      this.cepInvalid = false

      if (newVal.length < 8) {
        this.address = null
      }
    },
    labCurrent () {
      this.changePagination()
    },
  },
});
</script>

<style lang="scss" src="./laboratorySearch.scss"></style>